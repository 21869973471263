.ws-card {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    position: relative;
    /* backdrop-filter: blur(6.4px);
    background-color: rgba(255, 255, 255, 0.1); */
    background-repeat: no-repeat !important;
    background-position: center 20% !important;
    background-size: cover !important;
    overflow: hidden !important;
}


.ws-card>img {
    opacity: 0.5;
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.ws-card-title {
    text-align: center;
    font-size: 3.5rem;
    line-height: 3.5rem;
    color: #fff;
    width: 100%;
    text-transform: uppercase;
    height: 100px;
}

.ws-card-line {
    min-height: 5px;
    width: 60px;
    background-color: #fff;
    margin: 20px auto;
    z-index: 1;
}

.ws-new-card-line {
    min-height: 5px;
    width: 60px;
    background-color: #fff;
    margin: 20px auto;
    z-index: 2;
}

.ws-card-time {
    margin: 10px auto;
}

.ws-card-time>img {
    margin-right: 5px;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
}

.ws-card-img-bg {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 100%;
    z-index: 2;

    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(20, 20, 20, .9) 15%, rgba(83, 100, 141, 0) 70%);
}

.ws-card-menu-icon {
    position: absolute !important;
    top: 15px;
    right: 15px;
    z-index: 1000;
}

.news {
    padding: 5px 10px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(8.6985px);
    border-radius: 32px;
    color: #fff;
    margin-bottom: 20px;
}

.ws-title {
    position: absolute;
    z-index: 999;
    bottom: -22%;
    height: 68%;
    max-width: 100%;
    transition: transform .8s ease-out 100ms
}

.ws-new-title {
    position: absolute;

    height: 100%;
    max-width: 100%;
}

/* .ws-card:hover>.ws-title {
    transform: translateY(-150px);
    transition: .7s ease;
    cursor: pointer;
    z-index: 999;
}

.ws-card:hover>.ws-card-img-bg {
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(20, 20, 20, .9) 55%, rgba(83, 100, 141, 0) 90%);
} */

@media (max-width:360px) {
    .ws-card {
        width: 160px !important;
        max-height: 300px !important;
        min-height: 300px !important;
    }

    .ws-card-line {
        margin: 35px auto;
    }

    .ws-card-title {
        height: 50px;
    }

    .ws-new-card-line {
        margin: 20px auto;
    }
}


@media (max-width:400px) {
    .ws-card {
        width: 160px !important;
        max-height: 350px !important;
        min-height: 350px !important;
    }

    .ws-card-line {
        margin: 35px auto;
    }

    .ws-card-title {
        height: 50px;
    }

    .ws-new-card-line {
        margin: 20px auto;
    }
}

@media (max-width:576px) {
    .ws-card {
        width: 190px;
        max-height: 350px !important;
        min-height: 350px !important;
    }

    .ws-card-line {
        margin: 40px auto;
    }

    .ws-card-title {
        height: 50px;
    }
}

@media (max-width:768px) {
    .ws-card {
        max-height: 550px;
        min-height: 550px;
    }
}

@media (max-width:992px) {
    .ws-card {

        max-height: 500px;
        min-height: 500px;
    }
}

@media (max-width:3000px) {
    .ws-card {

        max-height: 555px;
        min-height: 555px;
    }
}